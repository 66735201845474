// @ts-strict-ignore
import React, { useEffect, useState } from 'react'

import { Spinner } from '@chakra-ui/react'

import { useAppContext } from 'app/AppContext'
import { UserApi } from 'data/api/userApi'

import { Box, Button, Flex, Input, LoadingScreen, Text } from 'v2/ui'
import { SimpleModalCompat as Modal } from 'v2/ui/components/SimpleModal'
import useModalToggle, { useModalDeclaration } from 'v2/ui/utils/useModalToggle'

import { LegacyBanner } from './app-users-modal/MigratedFromV3Banner'

const MODAL_KEY = 'SharingLink'
export const useSharingLinkModal = (user) => {
    return useModalDeclaration(MODAL_KEY, SharingLinkModal, user)
}

export const SharingLinkModal = () => {
    const modal = useModalToggle(MODAL_KEY)
    const { isOpen, toggle, data: user } = modal
    if (!user) return null

    return (
        <Modal
            isOpen={isOpen}
            onClose={toggle}
            height="auto"
            textAlign="center"
            actionsBorder={false}
            style={{}}
            title="Sharing Link"
            body={
                // @ts-ignore
                <SharingLink userId={user.user_id} userName={user.name || user.email} />
            }
            footer={
                <LegacyBanner
                    title="Stacker Classic feature"
                    subtitle="Only available on apps upgraded from Stacker Classic"
                    compact={true}
                    borderRadius="5px"
                    borderTopLeftRadius={0}
                    borderTopRightRadius={0}
                />
            }
        ></Modal>
    )
}

const SharingLink = ({ userId, userName }) => {
    const { selectedStack } = useAppContext()
    const [apiToken, setApiToken] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const height = '150px'

    // We get the key from impersonate initially
    useEffect(() => {
        UserApi.impersonate(userId).then(({ api_token }) => {
            setApiToken(api_token)
        })
    }, [userId])

    if (!apiToken || !selectedStack || !selectedStack.url) {
        return (
            <Box flexGrow={1} height={height} pt={2} pb={40}>
                <Spinner />
            </Box>
        )
    }

    const link = `https://${selectedStack.url}/login?api_token=${apiToken}`

    // Sends a request to regenerate the user's api token in the db
    // Access with the previous token will therefore be revoked
    const regenerateApiTokenForAuth = () => {
        setIsLoading(true)
        UserApi.regenerateApiTokenForAuth(userId)
            .then(({ api_token }) => {
                setApiToken(api_token)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Box height={height} pt={2} pb={40}>
            <Text size="sm" mb={6}>
                Anyone with the link will be able to log directly into the app as {userName}
            </Text>

            <LoadingScreen isLoading={isLoading} minHeight="100px">
                <Flex wrap="nowrap" mb={5}>
                    <Input
                        readonly
                        value={link}
                        variant="admin_white"
                        flexGrow={1}
                        style={{ cursor: 'auto' }}
                    />
                </Flex>

                <Box>
                    <Button
                        buttonSize="extraSmall"
                        icon="copy"
                        onClick={() => navigator.clipboard.writeText(link)}
                        variant="adminPrimaryV4"
                        mr={2}
                        mb={2}
                    >
                        Copy
                    </Button>

                    <Button
                        buttonSize="extraSmall"
                        variant="adminPrimaryV4"
                        icon="refresh"
                        onClick={regenerateApiTokenForAuth}
                        target="_blank"
                        mr={2}
                        mb={2}
                    >
                        Regenerate
                    </Button>
                    <Button
                        buttonSize="extraSmall"
                        icon="link"
                        href={link}
                        variant="adminPrimaryV4"
                        mb={2}
                        openInNewTab
                    >
                        Open
                    </Button>
                </Box>
            </LoadingScreen>
        </Box>
    )
}
