// @ts-strict-ignore
import React, { FC, useMemo } from 'react'

import { BoxProps } from '@chakra-ui/react'

import { useAppContext } from 'app/AppContext'

import { Flex, Icon, Text } from 'v2/ui'

const MigratedFromV3Banner: FC<BoxProps> = ({ ...style }) => {
    const { selectedStack, workspaceAccount } = useAppContext()

    // We show the 'this app has new user login features' only on NUM apps that have been migrated
    const migratedFromV3 = useMemo(
        () => workspaceAccount?.options.migrated_from_v3,
        [workspaceAccount]
    )
    // We show the 'this app has legacy user login features' on ALL NUMa apps, regardless of migration history
    const isNUMa = useMemo(() => selectedStack?.options?.sync_users_as_auths, [selectedStack])
    const showBanner = useMemo(() => migratedFromV3 || isNUMa, [migratedFromV3, isNUMa])

    const { icon, bannerColor, titleColor, titleContent, subtitle } = useMemo(() => {
        if (isNUMa) {
            return {
                icon: 'avatar',
                bannerColor: '#dad7ff',
                titleColor: '#1f2123',
                titleContent: 'This app was migrated from Stacker Classic',
                subtitle: (
                    <>
                        <Text fontSize="12px">
                            These users will continue to access this app in single-app mode as usual
                            at&nbsp;
                        </Text>
                        <Text fontSize="12px" fontWeight={600}>
                            {selectedStack?.url}
                        </Text>
                        <Icon
                            icon="copy"
                            size="sm"
                            marginLeft="4px"
                            cursor="pointer"
                            onClick={() => navigator.clipboard.writeText(selectedStack?.url)}
                        />
                    </>
                ),
                url: selectedStack?.url,
                buttonVariant: 'adminSecondaryV4',
            }
        }

        return {
            icon: 'users',
            bannerColor: '#f5f6f6',
            titleColor: '#456dd8',
            titleContent: 'This app has new user login features',
            subtitle: (
                <>
                    <Text fontSize="12px">
                        These users will now access this app and any new apps shared with them
                        at&nbsp;
                    </Text>
                    <Text fontSize="12px" fontWeight={600}>
                        {workspaceAccount?.url}
                    </Text>
                    <Icon
                        icon="copy"
                        size="sm"
                        marginLeft="4px"
                        cursor="pointer"
                        onClick={() => navigator.clipboard.writeText(workspaceAccount?.url || '')}
                    />
                </>
            ),
            buttonVariant: 'adminPrimaryV4',
        }
    }, [isNUMa, workspaceAccount, selectedStack])

    if (!showBanner) {
        return null
    }

    return (
        <LegacyBanner
            icon={icon}
            bannerColor={bannerColor}
            titleColor={titleColor}
            title={titleContent}
            subtitle={subtitle}
            {...style}
        />
    )
}

export const LegacyBanner = ({
    icon = 'avatar',
    bannerColor = '#dad7ff',
    titleColor = '#1f2123',
    title,
    subtitle,
    compact = false,
    ...style
}) => {
    return (
        <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            backgroundColor={bannerColor}
            paddingTop={compact ? '12px' : '20px'}
            paddingBottom={compact ? '12px' : '20px'}
            paddingLeft={compact ? '12px' : '15px'}
            paddingRight={compact ? '12px' : '15px'}
            {...style}
            flexWrap="nowrap"
        >
            <Icon icon={icon} size="sm" py="1px" color="#465dd8" />
            <Flex
                flexDirection="column"
                alignItems="flex-start"
                flexGrow="1"
                mx="10px"
                flexShrink={1}
            >
                <Text color={titleColor} fontSize={14} fontWeight={600}>
                    {title}
                </Text>
                <Flex
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    marginTop="5px"
                    fontSize="12px"
                    textAlign="left"
                >
                    {subtitle}
                </Flex>
            </Flex>
        </Flex>
    )
}

export default MigratedFromV3Banner
