export type HelpLinks = {
    [key: string]: string
}

const HELP_LINKS: HelpLinks = {
    USER_TABLE: 'https://docs.stacker.app/user-login/untitled#we-need-a-user-table',
    API_KEY:
        'https://docs.stacker.app/guides/getting-started-with-stacker/how-to-find-my-airtable-api-key',
    SHARE_BASE_LINK:
        'https://docs.stacker.app/guides/getting-started-with-stacker/how-to-find-your-airtable-share-base-link',
    PORTAL_NAME:
        'https://docs.stacker.app/guides/getting-started-with-stacker/what-is-a-good-app-name',
    CREATED_BY_FIELD: 'https://docs.stacker.app/pages/created-by-field',
    SHARING_LINKS: 'https://docs.stacker.app/user-login/user-registration-and-login/sharing-links',
    SUPPORT_ACCESS: 'https://docs.stacker.app/managing-your-account/support-access',
    ENABLING_DATA: 'https://docs.stacker.app/connecting-to-your-data/enabling-and-disabling-data',
    USER_ROLES:
        'https://docs.stackerhq.com/stacker-classic/permissions-and-security/set-up-user-roles',
    MULTIPLE_PORTALS: 'https://docs.stacker.app/managing-your-account/managing-multiple-apps',
    CUSTOM_DOMAINS: 'https://docs.stacker.app/setting-up-your-app/custom-domains',
    CUSTOM_SCRIPTS: 'https://docs.stacker.app/features/custom-scripts',
    CLIENT_LOGOS: 'https://docs.stacker.app/features/client-logos',
    CUSTOM_CSS: 'https://docs.stacker.app/features/custom-css',
    GSHEETS_SHEET_REQUIREMENTS:
        'https://docs.stacker.app/sheets-troubleshooting/onboarding/requirements',
    THIRD_PARTY_COOKIES:
        'https://docs.stacker.app/sheets-troubleshooting/onboarding/third-party-cookies-are-disabled-error',
    COLLABORATION:
        'https://help.stackerhq.com/editing-your-app/editing-layout/detail-pages/tabs#enabling-activity',
    PASSWORDLESS_LOGIN:
        'https://docs.stacker.app/user-login/user-registration-and-login/passwordless-login',
    CREATE_PERMS_RELAXED_MODE:
        'https://docs.stacker.app/controlling-access/advanced-record-creation-permissions',
    FAST_SYNC: 'https://help.stackerhq.com/fundamentals/adding-data-source/cache-frequency',
    CREATE_FORMS: 'https://docs.stacker.app/pages/create-forms',
    PERMISSIONS: 'https://www.stackerhq.com/help-article/introduction-to-permissions',
    CUSTOMER_ACCESS: 'https://docs.stackerhq.com/stacker-classic/customer-access/customer-access',
    FORMULA_FIELDS: 'https://docs.stackerhq.com/stacker-classic/data/formula-field',
    INLINE_FILTERS:
        'https://docs.stackerhq.com/stacker-classic/layout-types/list-display-types/list-options/list-inline-filters',
    CUSTOMER_SUCCESS_360_DATA_MEETING: 'https://stacker.chilipiper.com/router/data_360',
    AIRTABLE_REPAIR:
        'https://docs.stackerhq.com/stacker-classic/data/third-party-integrations/airtable/upgrading-airtable-data-connections#id-01h819rdfdzc8qe4xzazn3238n',
    AIRTABLE_OAUTH_LEARN_MORE:
        'https://docs.stackerhq.com/stacker-classic/data/third-party-integrations/airtable/upgrading-airtable-data-connections',
    INTERCOM_LEARN_MORE:
        'https://docs.stackerhq.com/stacker-classic/data/third-party-integrations/intercom-integration',
    FULLSTORY_LEARN_MORE:
        'https://docs.stackerhq.com/stacker-classic/data/third-party-integrations/fullstory-integration',
    SELF_SERVE_CUSTOM_SCRIPTS:
        'https://docs.stackerhq.com/stacker-classic/customization/custom-scripts#h_01h0jbrncmam9yagjmjb4b1hna',

    AIRTABLE_UPGRADE:
        'https://docs.stackerhq.com/stacker-classic/data/third-party-integrations/airtable/upgrading-airtable-data-connections',
    GSHEETS_LEARN_MORE:
        'https://docs.stackerhq.com/stacker-classic/data/third-party-integrations/airtable/upgrading-airtable-data-connections',
    CSV_IMPORT:
        'https://docs.stackerhq.com/stacker-classic/data/importing-data-into-stacker/importing-data-from-csv',
    LIST_FILTERS:
        'https://docs.stackerhq.com/stacker-classic/layout-types/list-display-types/list-options/list-filter',
    HOME: 'https://docs.stackerhq.com/stacker-classic',
}

export default HELP_LINKS
