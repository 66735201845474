import React, { FC, useCallback, useEffect, useState } from 'react'
import Vimeo from 'react-vimeo-embed'

import { StyledFlex } from 'features/AppSettings/DataSources/Airtable/StyledComponents'
import { useAppSettings } from 'features/workspace/AdminSideTray/hooks/useAppSettings'

import { Box, Button, Flex, Link, Text } from 'v2/ui'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'

import { useAirtableV2UpgradeReminder } from './hooks/useAirtableV2UpgradeReminder'
import HELP_LINKS from './helpLinks'

export const AirtableV2UpgradeModal: FC = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { open: openAppSettings } = useAppSettings()

    const { shouldShowReminder, setOneWeekReminder } = useAirtableV2UpgradeReminder()

    useEffect(() => {
        if (shouldShowReminder) {
            setIsOpen(true)
        }
    }, [shouldShowReminder])

    const onCloseModal = useCallback(() => {
        setIsOpen(false)
        setOneWeekReminder()
    }, [setOneWeekReminder])

    const onUpgradeClick = () => {
        onCloseModal()
        openAppSettings({ page: { name: 'data_sources' } })
    }

    return (
        <SimpleModalCompat
            size="470px"
            height="auto"
            padding="24px 20px"
            noDividers
            isSecondLayer
            zIndex={1501}
            isOpen={isOpen}
            showCloseButton={false}
        >
            <StyledFlex>
                <Vimeo
                    video="https://vimeo.com/video/855289451?h=dfdb77947"
                    autoplay
                    muted
                    loop
                    height={250}
                    width={430}
                    controls={false}
                />
                <Text fontSize="lg" fontWeight="bold" marginTop="24px">
                    Action required
                </Text>
                <Text marginTop="9px">
                    Your app has one or more Airtable data connectors that use an Airtable API key.
                </Text>
                <Box
                    marginTop="9px"
                    width="100%"
                    alignContent="center"
                    alignItems="center"
                    backgroundColor="#FFF0CB"
                    borderRadius="4px"
                >
                    <Text padding="12px 8px">
                        Airtable will stop supporting API keys after{' '}
                        <Text fontWeight="bold" display="inline">
                            February 1, 2024
                        </Text>
                        .
                    </Text>
                </Box>
                <Text marginTop="9px">
                    Upgrade your connectors{' '}
                    <Text fontWeight="bold" display="inline">
                        as soon as possible
                    </Text>{' '}
                    to make sure your apps continue working. Upgrading is free and only takes a few
                    minutes.
                </Text>
                <Flex width="100%" marginTop="20px">
                    <Button
                        flexGrow={1}
                        variant="Primary"
                        maxW={430}
                        buttonSize="large"
                        onClick={onUpgradeClick}
                    >
                        Upgrade now
                    </Button>
                </Flex>
                <Flex marginTop={5}>
                    <Link
                        href={HELP_LINKS.AIRTABLE_UPGRADE}
                        fontSize="medium"
                        variant="noHover"
                        color="#465DD8"
                        fontWeight="bold"
                    >
                        Learn more
                    </Link>
                </Flex>
            </StyledFlex>
        </SimpleModalCompat>
    )
}
