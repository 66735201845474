import React from 'react'

import { List, ListItem } from '@chakra-ui/react'

import HELP_LINKS from 'app/helpLinks'

import { Icon, Text } from 'v2/ui'

type AirtableSchemaSyncServiceErrorProps = {}

export const AirtableSchemaSyncServiceError: React.FC<AirtableSchemaSyncServiceErrorProps> = () => {
    return (
        <>
            <Text variant="error" mb={3}>
                <Icon icon="alert" display="inline" mr={2} />
                We can&apos;t connect to your Airtable base.
            </Text>
            <Text>This usually means something has changed, for example:</Text>
            <List styleType="disc" p={0} my={3}>
                <ListItem>Airtable API key</ListItem>
                <ListItem>Sharing link password</ListItem>
                <ListItem>Base sharing link</ListItem>
            </List>

            <a href={HELP_LINKS.AIRTABLE_REPAIR} target="_blank" rel="noreferrer">
                Find out how to repair your Airtable connection in our Help Docs here.
            </a>

            <Text mt={3}>
                If you have changed your <strong>base sharing link</strong>, or are still having
                problems, please contact us at{' '}
                <a href="mailto:support@stackerhq.com">support@stackerhq.com</a> and we&apos;ll be
                happy to help.
            </Text>
        </>
    )
}
